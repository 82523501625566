import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './PostDetail.css';

const API_URL = process.env.REACT_APP_API_URL || 'https://miniapp.tw1.ru/';

const PostDetail = ({ post, onClose, user }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');

  useEffect(() => {
    axios.get(`${API_URL}/api/comments/${post.id}`)
      .then(response => setComments(response.data))
      .catch(error => console.error('Ошибка при получении комментариев:', error));
  }, [post.id]);

  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleCommentSubmit = (e) => {
    e.preventDefault();
    if (!newComment.trim()) {
      return;
    }

    axios.post(`${API_URL}/api/comments`, {
      postId: post.id,
      userId: user.id,
      text: newComment
    })
      .then(response => {
        setComments(prevComments => [...prevComments, response.data]);
        setNewComment('');
      })
      .catch(error => console.error('Ошибка при добавлении комментария:', error));
  };

  const handleUsernameClick = (username) => {
    window.Telegram.WebApp.openTelegramLink(`https://t.me/${username}`);
  };

  const renderTextWithParagraphs = (text) => {
    return text.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  };

  return (
    <div className="post-detail">
      <div className="post-detail-content">
        <button className="close-button" onClick={onClose}>Закрыть</button>
        <h2>{post.username}</h2>
        <div>{renderTextWithParagraphs(post.caption)}</div>
        {post.imageUrl && <img src={`${API_URL}${post.imageUrl}`} alt={post.caption} style={{ maxWidth: '100%', height: 'auto' }} />}
        <p>{new Date(post.createdAt).toLocaleString()}</p>

        <h3>Комментарии</h3>
        <div className="comments">
          {comments.map(comment => (
            <div key={comment.id} className="comment">
              <span className="comment-username" onClick={() => handleUsernameClick(comment.user.username)}>{comment.user.username}</span>
              <p>{comment.text}</p>
              <p>{new Date(comment.createdAt).toLocaleString()}</p>
            </div>
          ))}
        </div>

        <form onSubmit={handleCommentSubmit} className="comment-form">
          <textarea
            value={newComment}
            onChange={handleCommentChange}
            placeholder="Добавьте комментарий"
            required
          />
          <button type="submit">Отправить</button>
        </form>
      </div>
    </div>
  );
};

PostDetail.propTypes = {
  post: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default PostDetail;
