import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './App.css';
import PostDetail from './PostDetail.js';
import NewPostModal from './routes/NewPostModal';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import PropTypes from 'prop-types';

const API_URL = process.env.REACT_APP_API_URL || 'https://miniapp.tw1.ru/';

function App() {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedPost, setSelectedPost] = useState(null);
  const [showNewPostModal, setShowNewPostModal] = useState(false);

  useEffect(() => {
    const initDataUnsafe = window.Telegram.WebApp.initDataUnsafe;
    const chatInstance = initDataUnsafe?.chat_instance;

    if (initDataUnsafe?.user && chatInstance) {
      const user = {
        id: initDataUnsafe.user.id,
        firstName: initDataUnsafe.user.first_name,
        lastName: initDataUnsafe.user.last_name || '',
        username: initDataUnsafe.user.username,
        languageCode: initDataUnsafe.user.language_code,
        isPremium: initDataUnsafe.user.is_premium || false,
        allowsWriteToPm: initDataUnsafe.user.allows_write_to_pm || false,
        chatInstance,
      };

      setUser(user);

      axios.post(`${API_URL}/api/auth`, user)
        .then(response => console.log('Пользователь сохранен:', response.data))
        .catch(error => console.error('Ошибка при сохранении пользователя:', error));
    } else {
      console.error('Не удалось получить initData или chatInstance');
    }
  }, []);

  useEffect(() => {
    if (user?.chatInstance) {
      axios.get(`${API_URL}/api/posts/${user.chatInstance}`)
        .then(response => {
          if (Array.isArray(response.data)) {
            setPosts(response.data);
          } else {
            console.error('Полученные данные не являются массивом:', response.data);
          }
        })
        .catch(error => console.error('Ошибка при получении постов:', error));
    }
  }, [user]);

  useEffect(() => {
    if (selectedPost || showNewPostModal) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [selectedPost, showNewPostModal]);

  const handlePostSubmit = (newPost) => {
    if (!user?.chatInstance) {
      console.error('chatInstance не найден');
      return;
    }

    const formData = new FormData();
    formData.append('caption', newPost.caption);
    formData.append('image', newPost.image);
    formData.append('chatInstance', user.chatInstance);
    formData.append('id', user.id);
    formData.append('username', user.username);

    axios.post(`${API_URL}/api/posts`, formData)
      .then(response => {
        setPosts(prevPosts => [response.data, ...prevPosts]);
        setShowNewPostModal(false);
        setTimeout(() => {
          const postElement = document.getElementById(response.data.id);
          if (postElement) {
            postElement.scrollIntoView({ behavior: 'smooth' });
          }
        }, 100);
      })
      .catch(error => console.error('Ошибка при создании поста:', error));
  };

  const handleDeletePost = (postId) => {
    axios.delete(`${API_URL}/api/posts/${postId}`)
      .then(() => {
        setPosts(prevPosts => prevPosts.filter(post => post.id !== postId));
      })
      .catch(error => console.error('Ошибка при удалении поста:', error));
  };

  const renderPreviewText = (text) => {
    const maxLength = 100; // Максимальная длина текста в превью
    const paragraphs = text.split('\n'); // Разделение текста на абзацы
    let previewText = '';
    let currentLength = 0;

    for (const paragraph of paragraphs) {
      if (currentLength + paragraph.length > maxLength) {
        // Если добавление этого абзаца превысит максимальную длину, добавляем его часть
        const remainingLength = maxLength - currentLength;
        previewText += paragraph.substring(0, remainingLength) + '...';
        break;
      } else {
        // Добавляем целый абзац и новую строку, если это не последний абзац
        previewText += paragraph + '\n';
        currentLength += paragraph.length + 1; // +1 за новую строку
      }
    }

    return previewText.trim(); // Удаляем лишние пробелы и переводы строк
  };

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleClosePostDetail = () => {
    setSelectedPost(null);
  };

  const handleOpenNewPostModal = () => {
    setShowNewPostModal(true);
  };

  const handleCloseNewPostModal = () => {
    setShowNewPostModal(false);
  };

  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={
            <div className="App">
              <h1>Посты</h1>
              <div className="posts">
                {Array.isArray(posts) && posts.map(post => (
                  <div key={post.id} id={post.id} className="post" onClick={() => handlePostClick(post)}>
                    <h2>{post.username}</h2>
                    <p>{renderPreviewText(post.caption)}</p>
                    {post.imageUrl && <img src={`${API_URL}${post.imageUrl}`} alt={post.caption} style={{ maxWidth: '100%', height: 'auto', maxHeight: '200px' }} />}
                    <p>{new Date(post.createdAt).toLocaleString()}</p>
                    {user?.id === post.userId && <button onClick={(e) => {
                      e.stopPropagation();
                      handleDeletePost(post.id);
                    }}>Удалить</button>}
                  </div>
                ))}
              </div>
              {selectedPost && <PostDetail post={selectedPost} onClose={handleClosePostDetail} user={user} />}
              {showNewPostModal && <NewPostModal onSubmit={handlePostSubmit} onClose={handleCloseNewPostModal} />}
              <button className="add-post-button" onClick={handleOpenNewPostModal}>+</button>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

App.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    username: PropTypes.string.isRequired,
  }),
};

export default App;
