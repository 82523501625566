import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './NewPostModal.css';

const NewPostModal = ({ onSubmit, onClose }) => {
  const [caption, setCaption] = useState('');
  const [image, setImage] = useState(null);
  const [error, setError] = useState('');

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'image/heic', 'image/tiff'];
    if (file && allowedTypes.includes(file.type)) {
      setImage(file);
      setError('');
    } else {
      setError('Недопустимый формат файла. Допустимые форматы: PNG, JPEG, JPG, HEIC, TIFF.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!caption.trim()) {
      setError('Подпись не может быть пустой');
      return;
    }
    if (image && image.size > 35 * 1024 * 1024) {
      setError('Размер файла не должен превышать 35 МБ.');
      return;
    }
    onSubmit({ caption, image });
  };

  return (
    <div className="new-post-modal">
      <div className="new-post-modal-content">
        <button className="close-button" onClick={onClose}>Закрыть</button>
        {error && <p className="error">{error}</p>}
        <form onSubmit={handleSubmit}>
          <textarea
            value={caption}
            onChange={handleCaptionChange}
            placeholder="Введите подпись"
            required
          />
          <input
            type="file"
            name="image"
            onChange={handleFileChange}
          />
          <button type="submit">Добавить пост</button>
        </form>
      </div>
    </div>
  );
};

NewPostModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewPostModal;
